<template>
    <div>
        <Modal :title="title" v-model="isShowModal" ref="noticeModal">
            <template v-slot:modal-title-icon v-if="insServicesCount > 0">
                <div class="has-event-icon">{{ insServicesCount }}</div>
            </template>
            <template v-slot:modal-content>
                <InsuranceContent
                    :dataList="dataList"
                    :isLoading="isLoadingTable"
                    :isDefaultClose="true"
                    :isShowModal="isShowModal"
                    class="ins-content"
                />
                <InsuranceImage
                    :dataList="dataList"
                    :isLoading="isLoadingTable"
                    :isDefaultClose="true"
                    :isShowModal="isShowModal"
                    class="image-content"
                />
                <InsuranceEventTable
                    ref="eventTable"
                    :dataList="dataList"
                    :isLoading="isLoadingTable"
                    :noticeId="noticeId"
                    v-model:selected="selectedNotices"
                />
            </template>
            <template v-slot:modal-bottom>
                <Button
                    :buttonWord="
                        $hasPermission('completeNotice') ? '下次再處理' : '關閉'
                    "
                    :buttonStyle="
                        $hasPermission('completeNotice') ? 'grey' : 'blue'
                    "
                    @click="resetChecked"
                />
                <Button
                    v-if="$hasPermission('completeNotice')"
                    buttonWord="完成"
                    buttonStyle="blue"
                    :disabled="
                        !$hasPermission('completeNotice') || !hasCheckedNotice
                    "
                    @click="confirm"
                />
            </template>
        </Modal>
        <CompleteNoticeModal
            v-model="isShowConfirmModal"
            v-model:selected="selectedNotices"
            @golaststep="isShowModal = true"
            @updateinsservices="updateInsServices"
        />
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import InsuranceContent from '@/containers/tia/InsuranceContent.vue'
import InsuranceImage from '@/containers/tia/InsuranceImage.vue'
import InsuranceEventTable from '@/containers/tia/InsuranceEventTable.vue'
import CompleteNoticeModal from '@/containers/tia/CompleteNoticeModal.vue'
import { getTiaInsuranceDetailAPI } from '@/assets/javascripts/api'

export default {
    name: 'InsuranceNoticeModal',
    emits: ['update:modelValue', 'updateinsservices'],
    components: {
        Modal,
        Button,
        InsuranceContent,
        InsuranceImage,
        InsuranceEventTable,
        CompleteNoticeModal
    },
    props: {
        modelValue: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        isLoading: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        insId: {
            type: [Number, String],
            default: function () {
                return null
            }
        },
        noticeId: {
            type: [Number, String],
            default: function () {
                return null
            }
        },
        manCode: {
            type: [Number, String],
            default: function () {
                return null
            }
        }
    },
    methods: {
        confirm: function () {
            if (!this.$hasPermission('completeNotice')) return
            this.$store.commit('set', {
                isShowModalForGa: true
            })
            this.$setGaEvent('showConfirm', 'click-Button')
            this.isShowModal = false
            this.isShowConfirmModal = true
        },
        resetChecked: function () {
            this.isShowModal = false
            this.$setGaEvent('closeNoticeModal', 'click-Button')
        },
        updateInsServices: function (completed) {
            this.$emit('updateinsservices', completed)
        }
    },
    computed: {
        title: function () {
            let titleName = '保單通知'
            return titleName
        },
        insServicesCount: function () {
            return (
                this.dataList?.insServices?.filter(
                    (service) => service.isChecked === false
                )?.length || 0
            )
        },
        hasCheckedNotice: function () {
            return Object.values(this.selectedNotices).some(
                (checked) => checked
            )
        }
    },
    watch: {
        isShowModal: {
            async handler() {
                this.$store.commit('set', {
                    isShowModalForGa:
                        this.isShowModal || this.isShowConfirmModal
                })
                if (
                    this.isShowModal === false &&
                    this.isShowConfirmModal === false
                ) {
                    this.selectedNotices = {}
                }
                this.$emit('update:modelValue', this.isShowModal)

                if (this.isShowModal) {
                    try {
                        this.isLoadingTable = true
                        this.dataList = {}
                        const res = await getTiaInsuranceDetailAPI(
                            this.insId,
                            this.manCode
                        )
                        this.dataList = res.data
                    } catch (error) {
                        this.$showErrorMessage(
                            '查無保單，將返回保單列表',
                            '查無保單編號，若有疑問請洽資訊客服'
                        )
                        this.dataList = {}
                        this.isShowModal = false
                    } finally {
                        this.isLoadingTable = false
                    }
                }
            },
            immediate: true
        },
        modelValue: function () {
            this.isShowModal = this.modelValue
        }
    },
    data() {
        return {
            isShowModal: this.modelValue,
            isShowConfirmModal: false,
            isLoadingTable: true,
            dataList: {},
            id: this.insId,
            selectedNotices: {}
        }
    },
    updated() {
        this.$nextTick(() => {
            // let noticeModal = this.$el
            const modalSize = this.$el.querySelector('.modal-size')
            let highlightItem = modalSize.querySelector('.highlight')
            if (!highlightItem) {
                this.$refs.noticeModal.$el
                    .querySelector('.modal-area')
                    .scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
            }
        })
    }
}
</script>

<style lang="scss" scoped>
.has-event-icon {
    position: relative;
    display: inline-block;
    text-align: center;
    color: $primary-white;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    border-radius: 100%;
    background-color: $primary-red;
    color: $primary-white;
    font-weight: bold;
    transform: scale(0.9);
    font-size: 12px;
    line-height: 23px;
}

.modal {
    &:deep(.modal-content) {
        @media screen and (min-width: 577px) {
            margin-bottom: 0;
        }
    }

    &:deep(.modal-size) {
        width: 780px;
        .event-table {
            table {
                min-width: 550px;
            }
        }
    }
    :deep(.edit-data-icon) {
        display: none;
    }
}
:deep(.modal-title) {
    .title {
        div {
            display: flex;
        }
    }
}

.image-content {
    :deep(.content-viewtitle) {
        font-size: 18px;
        font-weight: 500;
    }
}

:deep(.table-container) {
    max-height: 100%;
}
</style>
